<script setup>
  import { ref } from 'vue'

  defineProps({
    title: {
      default: '',
      type: String,
    },
  })

  const display = ref(false)
  const dim = ref(false)

  const open = () => {
    dim.value = true
    display.value = true
    document.addEventListener('keydown', onEscapeListener)
    document.addEventListener('backbutton', onEscapeListener)
  }
  const close = () => {
    display.value = false
    document.removeEventListener('keydown', onEscapeListener)
    document.removeEventListener('backbutton', onEscapeListener)
  }
  const afterLeave = () => {
    dim.value = false
  }

  const onEscapeListener = (event) =>{
    if (event.key === 'Escape') {
      close()
    }
  }

  defineExpose({ open, close })
</script>

<template>
  <div v-show="dim" class="fixed inset-0 z-40 flex items-center justify-center">
    <div class="fixed inset-0 bg-black bg-opacity-40"></div>
    <transition name="modal" @after-leave="afterLeave">
      <div v-if="display" class="container z-50 flex w-full max-h-full p-4">
        <div
          v-click-outside="close"
          class="flex flex-col flex-1 bg-white rounded-xl">
          <slot name="header">
            <div v-if="title" class="px-4 pt-2 text-lg font-bold">{{ title }}</div>
          </slot>
          <hr v-if="title || $slots['title']" class="my-2">
          <div class="flex-1 pl-4 pr-2 mr-2 overflow-y-auto">
            <slot></slot>
          </div>
          <template v-if="$slots['footer']">
            <hr class="my-2">
            <div class="px-4 pb-2">
              <slot name="footer"></slot>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
  .modal-enter-active,
  .modal-leave-active {
      transition: opacity 0.3s ease, margin-top 0.3s ease;
  }
  .modal-enter-from,
  .modal-leave-to {
      margin-top: -50px;
      opacity: 0;
  }
</style>
