<script setup>
  import Cleave from 'cleave.js'
  import { computed, onMounted, nextTick, ref } from 'vue'

  const emit = defineEmits(['update:modelValue'])

  const props = defineProps({
    modelValue: {
      type: [String, Number],
      required: false,
      default: '',
    },
    max: {
      type: Number,
      default: Number.MAX_VALUE,
    },
    min: {
      type: Number,
      default: 0,
    },
  })

  const inputCleave = ref()
  const cleave = ref(null)

  const textInput = computed({
    set: val => {
      const result = cleave.value?.getRawValue() ?? val
      if (result > props.max) {
        emit('update:modelValue', result + 1)
        nextTick(() => {
          emit('update:modelValue', props.max)
        })
      } else if (result < props.min) {
        emit('update:modelValue', result - 1)
        nextTick(() => {
          emit('update:modelValue', props.min)
        })
      } else if (result) {
        emit('update:modelValue', parseInt(result))
      } else {
        emit('update:modelValue', 1)
        nextTick(() => {
          emit('update:modelValue', 0)
        })
      }
    },
    get: () => props.modelValue,
  })

  onMounted(() => {
    cleave.value = new Cleave(inputCleave.value, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalMark: ',',
      delimiter: '.',
      numericOnly: true,
      numeralDecimalScale: 0,
    })
  })
</script>

<template>
  <input
    ref="inputCleave"
    v-model="textInput"
    inputmode="numeric">
</template>

<style lang="postcss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>