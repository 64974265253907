<script setup>
  import { computed } from 'vue'
  const props = defineProps({
    message: {
      type: [Array, String],
      default: () => [],
    },
  })
  const text = computed(() => {
    if (Array.isArray(props.message) && props.message.length) {
      return props.message[0]
    } else if (!Array.isArray(props.message)) {
      return props.message
    }

    return null
  })
</script>

<template>
  <div v-if="text" class="text-xs text-red-500">
    {{ text }}
  </div>
</template>

<style lang="postcss" scoped>
</style>