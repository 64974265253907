import { createApp } from 'vue'
import App from './App.vue'
import router from './route'

import './tailwind.css'

import globalComponents from './components'
import globalDirectives from './directives'

const app = createApp(App)

app.use(router)
  .use(globalComponents)
  .use(globalDirectives)
  .mount('#app')
