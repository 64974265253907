<script setup>
  import { inject, computed, watch } from 'vue'
  const props = defineProps({
    modelValue: {
      type: Object,
      required: true,
    },
  })
  const emit = defineEmits(['change'])

  const route = inject('route')
  const fmt = inject('fmt')

  const currentPage = computed(() => props.modelValue.current_page ?? 0)
  const lastPage = computed(() => props.modelValue.last_page ?? 0)
  const routePage = computed(() => route.query.page)
  const pages = computed(() => {
    const numOfButtons = 5
    let buttons = [currentPage.value]
    let i = 1
    while (buttons.length < numOfButtons && buttons.length < lastPage.value) {
      if (currentPage.value - i > 0) {
        buttons.unshift(currentPage.value - i)
      }
      if (currentPage.value + i <= lastPage.value) {
        buttons.push(currentPage.value + i)
      }
      i++
    }
    return buttons
  })
  watch(routePage, () => {
    emit('change')
  })
  const getRoute = p => {
    const page = p === 1 ? undefined : p
    return { ...route, query: { ...route.query, page } }
  }
</script>
<template>
  <nav
    v-if="lastPage > 1 && currentPage <= lastPage"
    role="navigation"
    aria-label="Pagination Navigation">
    <div class="flex justify-end my-4">
      <router-link :to="getRoute(1)" class="btn-pagination">&laquo;</router-link>
      <router-link
        v-for="page in pages"
        :key="page"
        :to="getRoute(page)"
        class="btn-pagination"
        :class="{'active': currentPage === page}">
        {{ fmt.number(page) }}
      </router-link>
      <router-link :to="getRoute(lastPage)" class="btn-pagination">&raquo;</router-link>
    </div>
  </nav>
</template>

<style lang="postcss">
.btn-pagination {
  @apply p-2 mr-1 text-xs text-center border rounded;
  @apply text-gray-700 hover:bg-gray-100;
  min-width: 2rem;
}
.btn-pagination.active {
  @apply bg-primary-500 border-primary-500 text-white hover:bg-primary-500;
}
</style>