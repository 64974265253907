import axios from 'axios'

let onNetworkError = () => {}
let onUnauthorized = () => {}
let onMaintenance = () => {}
let onForbidden = () => {}
let onNotFound = () => {}

const config = {
  baseURL: import.meta.env.VITE_URL_API,
  timeout: 30000, // 30s
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
}

const REQUEST = (conf) => new Promise((resolve, reject) => {
  axios.request({ ...config, ...conf })
    .then(response => {
      resolve(response.data)
    })
    .catch(error => {
      handleErrors(error)
      reject(error)
    })
})

const GET = (url, params = {}) => {
  return REQUEST({ method: 'get', url, params })
}
const POST = (url, data) => {
  return REQUEST({ method: 'post', url, data })
}
const DELETE = (url, data = {}) => {
  return REQUEST({ method: 'delete', url, data })
}
const PUT = (url, data) => {
  return REQUEST({ method: 'put', url, data })
}

const POSTFORMDATA = (url, data) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    if (Array.isArray(data[key])) {
      data[key].forEach(value => {
        formData.append(key + '[]', value)
      })
    } else {
      formData.append(key, data[key])
    }
  })

  return REQUEST({
    headers: { ...config.headers, 'Content-Type': 'multipart/form-data' },
    method: 'post',
    url,
    data: formData,
  })
}

/* https://github.com/axios/axios#handling-errors */
const handleErrors = (error) => {
  if (error.response) {
    /**
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    switch (error.response.status) {
    case 401: return onUnauthorized()
    case 403: return onForbidden()
    case 404: return onNotFound()
    case 503: return onMaintenance()
    default: break
    }
    // } else if (error.request) {
    /**
     * The request was made but no response was received.
     * `error.request` is an instance of XMLHttpRequest
     * in the browser and an instance of
     * http.ClientRequest in node.js
     */
  } else {
    /* Something happened in setting up the request that triggered an Error */

    onNetworkError()
  }
}

const setOnUnauthorized = fn => {
  onUnauthorized = fn
}
const setOnForbidden = fn => {
  onForbidden = fn
}
const setOnNotFound = fn => {
  onNotFound = fn
}
const setOnMaintenance = fn => {
  onMaintenance = fn
}
const setOnNetworkError = fn => {
  onNetworkError = fn
}
const useSanctum = () => {
  axios.defaults.withCredentials = true
  GET('sanctum/csrf-cookie')
}

export default {
  GET,
  POST,
  DELETE,
  PUT,
  POSTFORMDATA,
  setOnUnauthorized,
  setOnForbidden,
  setOnNotFound,
  setOnMaintenance,
  setOnNetworkError,
  useSanctum,
}