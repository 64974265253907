<script setup>
  import { inject, provide, ref } from 'vue'
  import NavbarBottom from './NavbarBottom.vue'
  import ErrorForbidden from '@/views/errors/Forbidden.vue'
  import ErrorNotFound from '@/views/errors/NotFound.vue'

  const api = inject('api')
  const httpStatus = inject('httpStatus')
  const user = ref(null)

  api.GET('home/init').then(response => {
    user.value = response.user
  })

  provide('user', user)
</script>

<template>
  <div v-if="user">
    <div class="container min-h-screen pb-16 bg-primary-50">
      <error-forbidden v-if="httpStatus === 403" />
      <error-not-found v-else-if="httpStatus === 404" />
      <router-view v-else />
    </div>
    <navbar-bottom />
  </div>
</template>

<style lang="postcss" scoped>
</style>