<script setup>
  import { inject, ref, computed } from 'vue'

  const api = inject('api')
  const router = inject('router')
  const route = inject('route')

  const username = ref('')
  const password = ref('')
  const submitting = ref(false)
  const errors = ref({})

  const form = computed(() => {
    let f = {
      password: password.value,
      remember: true,
    }
    if (username.value.includes('@')) {
      return { ...f, email: username.value }
    }
    return { ...f, username: username.value }
  })

  const submitForm = async () => {
    submitting.value = true
    try {
      await api.POST('login', form.value)
      router.replace(route.query.r ?? '/')
    } catch (error) {
      if (error.response?.status === 422) {
        errors.value = error.response.data.errors
      } else if (error.response?.status === 419) {
        errors.value.other = 'Refresh halaman dan coba lagi'
      }
    } finally {
      submitting.value = false
    }
  }
</script>

<template>
  <form @submit.prevent="submitForm">
    <h1 class="mb-6 text-xl font-bold">Login</h1>
    <div class="mb-6 space-y-4">
      <app-input
        id="username"
        v-model="username"
        autofocus
        required
        placeholder="Username atau Email" />
      <app-input
        id="password"
        v-model="password"
        required
        placeholder="Password"
        type="password"
        :error="errors.username" />
      <app-error :message="errors.other" />
    </div>

    <div class="mb-2">
      <app-button class="w-full btn-primary" type="submit" :loading="submitting">
        Masuk
      </app-button>
    </div>
    <div class="text-center">
      <router-link :to="{name: 'forgot'}" class="text-sm text-primary-500">
        Lupa username / password?
      </router-link>
    </div>
  </form>
</template>

<style lang="postcss" scoped>
</style>