<script setup>
  import { provide, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import PwaUpdatePrompt from './PwaUpdatePrompt.vue'
  import Toast from '@/components/Toast'
  import ErrorMaintenance from '@/views/errors/Maintenance.vue'

  import format from './functions/format'
  import api from './functions/api'

  const router = useRouter()
  const route = useRoute()
  const AppToast = Toast()

  const httpStatus = ref(null)

  api.setOnUnauthorized(() => {
    if (route.name !== 'login') {
      // const path = location.pathname.substring(1) + location.search
      const query = route.fullPath ? { r: route.fullPath } : {}
      router.replace({ name: 'login', query })
    }
  })
  api.setOnMaintenance(() => {
    router.replace({ name: 'maintenance' })
  })
  api.setOnNotFound(() => {
    httpStatus.value = 404
  })
  api.setOnForbidden(() => {
    httpStatus.value = 403
  })
  api.useSanctum()

  const setTitle = title => {
    document.title = title + ' | Jasa Servis AC | Cuci AC | Bongkar Pasang AC'
  }

  provide('fmt', format)
  provide('api', api)
  provide('router', router)
  provide('route', route)
  provide('httpStatus', httpStatus)
  provide('setTitle', setTitle)

  watch(() => route.fullPath, () => {
    httpStatus.value = null
  })
</script>

<template>
  <error-maintenance v-if="httpStatus === 503" />
  <router-view v-else />
  <app-toast />
  <pwa-update-prompt />
</template>

<style lang="postcss" scoped>
</style>
