let clickOutsideEvent = () => {}
const created = function (el, binding, vnode) {
  clickOutsideEvent = function (event) {
    // check if click is outside the el and his children
    if (!(el == event.target || el.contains(event.target))) {
      // call method provided in attribute value
      if (typeof binding.value === 'function') {
        binding.value(event)
      }
    }
  }
  window.addEventListener('mousedown', clickOutsideEvent)
}
const unmounted = function () {
  window.removeEventListener('mousedown', clickOutsideEvent)
}
export default {
  created,
  unmounted,
}
