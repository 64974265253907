<script setup>
  import { ref, computed, useAttrs } from 'vue'
  import AppInputNumber from '@/components/InputNumber.vue'

  const emit = defineEmits(['update:modelValue'])
  const attrs = useAttrs()
  const props = defineProps({
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    error: {
      type: [String, Array],
      default: '',
    },
    id: {
      type: String,
      default: () => 'input-' + Math.floor(Math.random() * 100000 + 1),
    },
  })

  const value = computed({
    get: () => props.modelValue,
    set: newValue => {
      emit('update:modelValue', newValue)
    },
  })

  // input type password
  const showPassword = ref(false)
</script>

<template>
  <div class="relative">
    <textarea
      v-if="type === 'textarea'"
      :id="id"
      v-model="value"
      class="peer"
      :class="{'has-label': attrs.placeholder}"
      v-bind="attrs"></textarea>
    <app-input-number
      v-else-if="type === 'number'"
      :id="id"
      v-model.number="value"
      type="text"
      class="peer"
      :class="{'has-label': attrs.placeholder}"
      v-bind="attrs" />
    <input
      v-else
      :id="id"
      v-model="value"
      :type="showPassword ? 'text' : type"
      class="peer"
      :class="{'!pr-10': type === 'password', 'has-label': attrs.placeholder}"
      v-bind="attrs">
    <label v-if="attrs.placeholder" :for="id">
      {{ attrs.placeholder }}
    </label>
    <div v-if="parseInt(attrs.maxlength ?? 0)" class="absolute text-gray-500 right-2 top-6 text-xxs">
      {{ parseInt(attrs.maxlength) - modelValue.length }}
    </div>
    <div v-else-if="type === 'password'" class="absolute block text-gray-500 peer-placeholder-shown:hidden right-4 top-5 py-0.5">
      <button type="button" @click="showPassword = !showPassword">
        <fa-icon v-if="showPassword" :icon="['fas', 'eye-slash']" fixed-width />
        <fa-icon v-else :icon="['fas', 'eye']" fixed-width />
      </button>
    </div>
    <app-error class="px-4 pt-1" :message="error" />
  </div>
</template>

<script>
  export default {
    inheritAttrs: false,
  }
</script>

<style lang="postcss" scoped>
  input, textarea {
    @apply border px-4 w-full rounded;
    @apply placeholder-white py-1 placeholder-shown:py-3;
    @apply focus:outline-none;

    &.has-label {
      @apply pt-5;
    }
  }
  label {
    @apply absolute left-4 text-xs leading-none font-bold text-gray-800 transition-all pointer-events-none select-none top-1.5;
    @apply peer-placeholder-shown:bg-transparent peer-placeholder-shown:top-3.5 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-placeholder-shown:text-gray-500;
  }
</style>