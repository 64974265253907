import { createRouter, createWebHistory } from 'vue-router'
import LayoutApp from '@/layouts/Index.vue'
import LayoutAuth from '@/layouts/Auth.vue'

import Login from '@/views/auth/Login.vue'

const routes = [
  {
    path: '',
    component: LayoutApp,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/home/Index.vue'),
      },
    ],
  },
  {
    path: '/absen',
    component: LayoutApp,
    children: [
      {
        path: '',
        name: 'attendance create',
        component: () => import('@/views/attendance/Create.vue'),
      },
      {
        path: '/laporan/absen',
        name: 'attendance reports',
        component: () => import('@/views/attendance/reports/Index.vue'),
      },
      {
        path: '/laporan/absen/mingguan',
        name: 'attendance reports overview weekly',
        component: () => import('@/views/attendance/reports/weekly/Overview.vue'),
      },
      {
        path: '/laporan/absen/bulanan',
        name: 'attendance reports overview monthly',
        component: () => import('@/views/attendance/reports/monthly/Overview.vue'),
      },
    ],
  },
  {
    path: '/karyawan',
    component: LayoutApp,
    children: [
      {
        path: '',
        name: 'employee',
        component: () => import('@/views/employee/Index.vue'),
      },
      {
        path: 'baru',
        name: 'employee create',
        component: () => import('@/views/employee/Create.vue'),
      },
      {
        path: 'riwayat-gaji/:id',
        name: 'employee salary history',
        props: true,
        component: () => import('@/views/employee/SalaryHistory.vue'),
      },
      {
        path: ':id',
        name: 'employee show',
        props: true,
        component: () => import('@/views/employee/Show/Index.vue'),
      },
    ],
  },
  {
    path: '/gajian',
    component: LayoutApp,
    children: [
      {
        path: '',
        name: 'salary',
        component: () => import('@/views/salaries/Index.vue'),
      },
      {
        path: 'karyawan/:id',
        name: 'salary detail',
        props: true,
        component: () => import('@/views/salaries/Detail/Index.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: LayoutAuth,
    children: [
      {
        path: '',
        name: 'login',
        component: Login,
      },
      {
        path: '/forgot',
        name: 'forgot',
        component: () => import('@/views/auth/Forgot.vue'),
      },
    ],
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/errors/Forbidden.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/errors/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
