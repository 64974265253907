import {
  format,
  formatDistance,
  isSameMonth,
  isSameYear,
  isSameDay,
  isValid,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns/esm'
import { id } from 'date-fns/esm/locale'
const date = (dt, fmt = 'd MMM yyyy') => { // format default : 13 Jan 2020
  if (!dt) return '-'

  dt = isValid(dt) ? dt : new Date(dt)

  return format(dt, fmt, { locale: id })
}
const dateRelative = dt => { // format : 5 minutes ago
  if (!dt) return '-'

  dt = isValid(dt) ? dt : new Date(dt)

  return formatDistance(new Date(dt), new Date(), { locale: id, addSuffix: true })
}
const dateRange = (start, end) => {
  // Same day => 30 Jun 2021
  // Same month => 1 - 30 Jun 2021
  // Different month same year => 1 Mar - 15 Mei 2021
  // Different year => 15 Des 2021 - 15 Jan 2022

  start = isValid(start) ? start : new Date(start)
  end = isValid(end) ? end : new Date(end)
  let formatStart = ''
  const formatEnd = 'd MMM yyyy'
  if (isSameDay(start, end)) {
    return date(start, formatEnd)
  }

  if (!isSameYear(start, end)) {
    formatStart = formatEnd
  } else if (!isSameMonth(start, end)) {
    formatStart = 'd MMM'
  } else {
    formatStart = 'd'
  }

  return date(start, formatStart) + ' - ' + date(end, formatEnd)
}
const dateRange2 = (start, end) => {
  return date(start, 'dd MMM yyyy') + ' - ' + date(end, 'dd MMM yyyy')
}

const hourRange = (start, end) => {
  // calculate start and end (HH:mm) into values like 9j 45m

  if (!start || !end) return ''

  const checkin = new Date('2000-01-01 ' + start)
  const checkout = new Date('2000-01-01 ' + end)

  const differenceHours = differenceInHours(checkout, checkin)
  const differenceMinutes = differenceInMinutes(checkout, checkin) % 60

  return `${differenceHours}j ${differenceMinutes}m`
}
export default {
  date,
  dateRelative,
  dateRange,
  dateRange2,
  hourRange,
}
