import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faCalendar,
  faChevronLeft,
  faChevronRight,
  faHouse,
  faSpinner,
  faSquareCheck,
  faUserClock,
  faUserGroup,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
library.add(
  faCalendar,
  faChevronLeft,
  faChevronRight,
  faHouse,
  faSpinner,
  faSquareCheck,
  faUserClock,
  faUsers,
  faUserGroup,
)

import {
  faSquare,
} from '@fortawesome/free-regular-svg-icons'
library.add(
  faSquare,
)

export default {
  install (Vue) {
    Vue.component('FaIcon', FontAwesomeIcon)
  },
}