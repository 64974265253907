<script setup>
  // https://vite-plugin-pwa.netlify.app/frameworks/vue.html#periodic-sw-updates
  import { ref } from 'vue'
  import { useRegisterSW } from 'virtual:pwa-register/vue'

  const { needRefresh, updateServiceWorker } = useRegisterSW()
  const updating = ref(false)
  const updateSW = () => {
    updating.value = true
    updateServiceWorker()
  }
</script>

<template>
  <div
    v-if="needRefresh"
    class="fixed bottom-0 right-0 z-10 p-4 m-4 bg-white border shadow-md"
    role="alert">
    <div class="mb-2">
      New content available
    </div>
    <div class="text-center">
      <button
        class="px-4 py-1 mx-auto text-sm border outline-none"
        @click="updateSW">
        <fa-icon v-if="updating" :icon="['fas', 'spinner']" spin />
        <template v-else>Update</template>
      </button>
    </div>
  </div>
</template>
