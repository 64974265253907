import Button from './Button.vue'
import ErrorMessage from './ErrorMessage.vue'
// import Image from './ImageWrapper.vue'
import Input from './Input.vue'
import Modal from './Modal.vue'
import Pagination from './Pagination.vue'
import FontAwesome from './FontAwesome'
import Navbar from '@/layouts/Navbar.vue'
export default {
  install (Vue) {
    Vue.component('AppButton', Button)
    Vue.component('AppError', ErrorMessage)
    // Vue.component('AppImg', Image)
    Vue.component('AppInput', Input)
    Vue.component('AppModal', Modal)
    Vue.component('AppPagination', Pagination)
    Vue.component('TheNavbar', Navbar)
    Vue.use(FontAwesome)
  },
}